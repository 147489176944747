import { TableReport, PDFReview, SendSingleFileModal, SendAllModal, JidoUtils } from "./kraftheinz-jido-seisei.es.js";
import _ from "lodash";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import { JIDO_SEISEI_FILE_STORAGE } from "@kraftheinz/common";
const apiUrl$1 = "#{VUE_APP_API_URL}#";
var __vue2_script$1 = {
  name: "ReportDetail",
  components: {
    TableReport,
    PDFReview,
    SendSingleFileModal,
    SendAllModal
  },
  mixins: [JidoUtils],
  inject: ["resourceName", "crud"],
  data() {
    const {
      id,
      saleName
    } = this.$route.params;
    const timeSelected = this.$route.query;
    return {
      folderId: id,
      saleName,
      apiUrl: apiUrl$1,
      timeSelected,
      daFileUrl: `${apiUrl$1}/jidoseisei/saleman/${id}/dafiles`,
      reviewFilePDFUrl: `${apiUrl$1}/jidoseisei/preview`,
      tableHeader: [{
        key: "daFile",
        title: "\u30D5\u30A1\u30A4\u30EB\u540D",
        hasIcon: true,
        type: "text"
      }, {
        key: "amount",
        title: "\u91D1\u984D",
        hasIcon: false,
        type: "text",
        width: 120
      }, {
        key: "isApproved",
        title: "\u627F\u8A8D\u6E08",
        hasIcon: false,
        type: "icon",
        width: 80
      }, {
        key: "updated",
        title: "\u66F4\u65B0\u65E5",
        hasIcon: false,
        type: "text",
        width: 120
      }, {
        key: "approved",
        title: "\u627F\u8A8D\u8005",
        hasIcon: false,
        type: "text",
        width: 120
      }, {
        key: "sentAt",
        title: "\u9001\u4FE1\u65E5",
        hasIcon: false,
        type: "text",
        width: 120
      }],
      isVisible: false,
      isDownloading: false,
      isVisibleSendSingleModal: false,
      isVisibleSendAllModal: false,
      isFetching: true,
      base64File: null,
      recordSelected: null,
      typeOfFile: "",
      dataTable: []
    };
  },
  computed: {
    menuItems() {
      return [{
        key: "salePerson",
        title: `${this.timeSelected.month}/${this.timeSelected.year}`,
        path: `/jido-seisei/payment-statement-creation/report?month=${this.timeSelected.month}&year=${this.timeSelected.year}`
      }, {
        key: "saleName",
        title: `${this.saleName}`,
        path: ""
      }];
    },
    isFetchingMonth() {
      if (this.$store.state["jidoseisei.common.list-month-of-year"].isFetchingList)
        return true;
      else {
        if (this.isFetching)
          return true;
      }
      return false;
    },
    pagination() {
      const {
        page,
        count,
        pageSize
      } = this.crud.getPagination();
      return {
        current: page,
        showSizeChanger: true,
        total: count,
        pageSize
      };
    }
  },
  watch: {
    timeSelected(newVal, oldVal) {
      if (newVal !== oldVal)
        this.fetchList();
    },
    async $route(to, from) {
      if (to.query !== from.query) {
        this.isFetching = true;
        await this.fetchList();
      }
    }
  },
  async mounted() {
    this.crud.setPagination({
      count: 0,
      page: 1
    });
    await this.fetchList();
  },
  methods: {
    async fetchListDetail() {
      try {
        const res = await this.crud.fetchList(this.daFileUrl, true, true, "");
        if (res) {
          this.convertData(res.data);
        }
        this.isFetching = false;
      } catch (err) {
        console.log(err);
      }
    },
    async fetchList(isSearching = false, value = "") {
      if (!isSearching) {
        this.isFetching = true;
        this.crud.deleteFilter("DaFile");
        await this.fetchListDetail();
        return;
      }
      const val = {
        operator: "Contains",
        value
      };
      this.crud.setFilter("DaFile", val);
      await this.fetchListDetail();
    },
    convertDate(date) {
      return date ? `${this.$moment(date).format("hh:mm A")}
${this.$moment(date).format("DD-MMM-YYYY")}` : "";
    },
    convertData(arr) {
      let arrData = [...arr];
      if (arrData.length > 0) {
        arrData.map((obj) => {
          let updateDate = this.convertDate(obj["updatedAt"]);
          let approvedDate = this.convertDate(obj["approvedAt"]);
          let sentDate = this.convertDate(obj["sentAt"]);
          let customUpdateValue = obj["updatedBy"] ? `${obj["updatedBy"]}
${updateDate}` : updateDate;
          let customApproveValue = obj["approvedBy"] ? `${obj["approvedBy"]}
${approvedDate}` : approvedDate;
          let canSent = +obj["amount"] > 0 && obj["isApproved"] ? true : false;
          let amount = this.formatCurrency(+obj["amount"]);
          obj = Object.assign(obj, {
            updated: customUpdateValue,
            approved: customApproveValue,
            amount: `\xA5${amount}`,
            sentAt: sentDate,
            canSent
          });
        });
      }
      this.dataTable = [...arr];
    },
    onSearchByWord: _.debounce(async function(e) {
      await this.fetchList(true, e.target.value);
    }, 400),
    async clickRow(val) {
      try {
        this.isDownloading = true;
        const res = await this.axios.get(`${this.reviewFilePDFUrl}/${val.id}`);
        if (res && res.data) {
          this.isDownloading = false;
          this.recordSelected = Object.assign({}, val, {
            fileApprovedId: res.data.fileApprovedId
          });
          this.base64File = res.data.base64;
          this.isVisible = true;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async popupSendSingleModal(record) {
      try {
        const res = await this.axios.get(`${this.daFileUrl}/${record.id}`);
        if (res && res.data) {
          this.recordSelected = res.data;
          this.isVisibleSendSingleModal = true;
        }
      } catch (err) {
        console.log(err);
      }
    },
    closedSendSingleModal(val) {
      this.isVisibleSendSingleModal = val;
      this.recordSelected = null;
    },
    popupSendAllModal(val) {
      this.isVisibleSendAllModal = val;
    },
    async onDownload(selectedFile) {
      this.isDownloading = true;
      this.typeOfFile = selectedFile.type.toString();
      const fileName = selectedFile.record.daFile ? selectedFile.record.daFile : "";
      const params = {
        Year: {
          operator: "Eq",
          value: this.timeSelected.year
        },
        Month: {
          operator: "Eq",
          value: this.timeSelected.month
        },
        SaleNameId: {
          operator: "In",
          value: this.folderId
        },
        CustomerId: {
          operator: "In",
          value: selectedFile.record.id
        }
      };
      try {
        await this.handleDownload(this.typeOfFile, params, fileName);
        this.isDownloading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async onDownloadAll(selectedFile) {
      this.isDownloading = true;
      this.typeOfFile = selectedFile.type.toString();
      const params = {
        Year: {
          operator: "Eq",
          value: this.timeSelected.year
        },
        Month: {
          operator: "Eq",
          value: this.timeSelected.month
        },
        SaleNameId: {
          operator: "In",
          value: this.folderId
        }
      };
      try {
        await this.handleDownload(this.typeOfFile, params);
        this.isDownloading = false;
      } catch (err) {
        console.log(err);
      }
    },
    onTableChange(pagination) {
      const {
        current,
        pageSize
      } = pagination;
      this.crud.setPagination({
        ...this.crud.getPagination(),
        page: current,
        pageSize
      });
      this.fetchListDetail();
    },
    onUploadFile(objFile) {
      const h = this.$createElement;
      if (objFile) {
        this.$confirm({
          title: h("p", {
            "class": "fz-18 font-weight-bold"
          }, ["\u3053\u306E\u30D5\u30A1\u30A4\u30EB\u306E\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9\u3092\u7D9A\u304D\u307E\u3059\u304B?"]),
          content: h("div", {
            "class": "d-flex align-items-center"
          }, [h("a-icon", {
            "attrs": {
              "type": "file-excel",
              "theme": "filled"
            },
            "class": "fz-18",
            "style": "color:#15B982"
          }), h("p", {
            "class": "ml-3 mb-0 fz-18",
            "style": "word-break: break-word;"
          }, [objFile.file.name])]),
          cancelText: "\u30AD\u30E3\u30F3\u30BB\u30EB",
          okText: "\u3000\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9",
          okType: "primary",
          onOk: async () => await this.uploadFile(objFile)
        });
      }
    },
    async uploadFile(objFile) {
      try {
        let formData = new FormData();
        formData.append("file", objFile.file);
        const res = await this.axios.post(`${this.apiUrl}/jidoseisei/filestorages/upload/${objFile.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        if (res) {
          this.$notification["success"]({
            message: "Upload successfully"
          });
          this.refreshPage(true);
        }
      } catch (err) {
        this.$notification["error"]({
          message: err.response.data.message || "Errors encountered!"
        });
      }
    },
    async refreshPage(val) {
      if (val) {
        this.closedSendSingleModal(false);
        await this.fetchList();
      }
    },
    async closedModal(val) {
      this.isVisible = val;
      await this.fetchList();
    },
    formatCurrency(value) {
      if (this._.isNil(value))
        return null;
      if (typeof value !== "number")
        return value.trim();
      return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2
      }).format(value);
    }
  }
};
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "w-100 h-100", staticStyle: { "background": "white" } }, [_c("table-report", { attrs: { "custom-url": _vm.daFileUrl, "is-tbl-folder": false, "folder-name": _vm.saleName, "is-fetch": _vm.isFetchingMonth, "is-downloading": _vm.isDownloading, "time-selected": _vm.timeSelected, "data-table": _vm.dataTable, "menu-items": _vm.menuItems, "row-keys": _vm.tableHeader, "pagination": _vm.pagination }, on: { "click-row": _vm.clickRow, "on-download": _vm.onDownload, "on-download-all": _vm.onDownloadAll, "on-upload-file": _vm.onUploadFile, "on-table-change": _vm.onTableChange, "on-popup-send-single-modal": _vm.popupSendSingleModal, "on-popup-send-all-modal": _vm.popupSendAllModal, "search-by-word": _vm.onSearchByWord } }), _c("p-d-f-review", { attrs: { "selected-record": _vm.recordSelected, "is-visible": _vm.isVisible, "base64": _vm.base64File }, on: { "closed-modal": _vm.closedModal, "on-download": _vm.onDownload, "refresh-page": _vm.refreshPage } }), _c("send-single-file-modal", { attrs: { "record-selected": _vm.recordSelected, "is-visible": _vm.isVisibleSendSingleModal }, on: { "closed-modal": _vm.closedSendSingleModal, "refresh-page": _vm.refreshPage } }), _c("send-all-modal", { attrs: { "folder-id": +_vm.folderId, "is-visible": _vm.isVisibleSendAllModal }, on: { "closed-modal": _vm.popupSendAllModal } })], 1);
};
var staticRenderFns$1 = [];
var ReportDetail_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".fz-18[data-v-4979b8fb]{font-size:18px}\n")();
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "4979b8fb", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ReportDetail = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "w-100 h-100", staticStyle: { "background": "white" } }, [_c("resource", { attrs: { "name": "jidoseisei.saleman.dafiles", "api-url": _vm.apiUrl, "page": _vm.page } }, [_c("report-detail")], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: { ReportDetail },
  data() {
    return {
      apiUrl,
      page: JIDO_SEISEI_FILE_STORAGE
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
